.longtextSummary {
	max-width: 60em;

	height: 95vh;
	max-height: 95vh;
	width: 95vw;
	display: flex;
	flex-flow: column;
	overflow: auto;

	.aiBadge {
		display: inline-flex;
		color: @color-signal;
		border: 1px solid @color-signal;
		border-radius: @badge[border-radius];
		#set.text(10, 18);
		#set.padding(0, 6);
		text-transform: uppercase;
		align-self: flex-start;
		font-weight: bold;
		#set.margin(bottom, 6);
	}

	.dialog__title {
		#set.text(26, 32);
		padding-right: .em(8)[];
		// color: @color-primary;
	}

	&.has\:longTitle .dialog__title {
		#set.text(20, 26);
	}
	&.has\:veryLongTitle .dialog__title {
		#set.text(14, 20);
	}

	.dialog__description {
		#set.text(13, 24);
		color: @color-primary;
	}

	.dialog__content {
		position: relative;
		flex: 1;
		display: flex;
		flex-flow: column;
	}

	&__content {
		// height: 100%;
		flex: 1;
	}

	.markdown {
		// background-color: red;
		p {
			#set.margin(0, 0, 8, 0);
		}
	}

	&__stickyHeader {
		position: sticky;
		top: 0;
		background-color: @color-bg;
		z-index: 1;
	}

	.dialog__close {
		display: block;
		background-color: fade(@color-text-faded, 20%);
		color: @color-primary-faded;
		height: 3rem;
		width: 3rem;
		position: absolute;
		top: .em(-8)[];
		right: .em(-8)[];
		border-radius: 50%;
		transition: all 0.3s;
		font-weight: 900;

		&:focus {
			outline: none;
			background-color: fade(@color-text-faded, 40%);
		}

		&:focus-visible {
			outline: revert;
			background-color: fade(@color-text-faded, 40%);
		}

		&::before {
			font-size: 1.5em;
			font-weight: 900;
		}

		&:hover {
			color: @color-primary;
			background-color: fade(@color-text-faded, 40%);
		}
	}


	.dialog__actions {
		justify-content: flex-start;
		flex-flow: row wrap;
		gap: .em(6)[];
    position: sticky;
    bottom: 0;

		a {
			color: @color-white;
		}
	}

	.copyMenu {
		margin: 0;
	}

	.spinner {
		position: absolute;
		width: 100%;
		// bottom: 12em;
		bottom: 50%;
	}


	// when writing a list
	&.is\:writing .markdown > ul:last-child li:last-child {
		// background-color: red;
		&::after {
			content: "|";
			animation: blinking-cursor 1s linear infinite;
			// font-weight: bold;
		}
	}

	// when writing anything else
	&.is\:writing .markdown > *:not(ul):last-child {
		// background-color: red;
		&::after {
			content: "|";
			animation: blinking-cursor 1s linear infinite;
			// font-weight: bold;
		}
	}

	@media @tablet {
		min-height: 70vh;

		height: auto;
	}

	@media @desktop {
		padding: .em(42) [] .em(48) [] .em(36) [] .em(48) [];
		min-height: 56rem;

		.dialog__close {
			top: .em(-20)[];
			right: .em(-30)[];

		}
	}
}
